<template>
  <div>
    <!-- 购买通知 -->
    <el-row style="margin-bottom: 20px" justify="center" align="middle">
      <el-col style="text-align: center" :span="windowWidth < 768 ? 24 : 24">
        <v-runtime-template :template="infos.buy"></v-runtime-template>
      </el-col>
    </el-row>


    <div v-for="coupon in account.coupons" :key="coupon.id">
      <el-alert class="coupons-alert" type="info" effect="light" closable>
        您有一张 <span style="color: red">{{ coupon.discount / 10.0 }}折</span> 优惠券，将在 {{
          dayjs(coupon.endTime).format('YYYY年MM月DD日 HH:mm') }}过期
      </el-alert>
    </div>

    <el-row justify="center" :gutter="20">
      <el-col v-for="itemsCategory in itemsCategories" :key="itemsCategory.id" :span="getCardColSpan">
        <el-card body-style="padding:10px" class="card-margin-bottom">
          <el-tag style="width:100%;height:30px;font-size: 22px" type="info">{{ itemsCategory.name }}</el-tag>
          <div v-if="itemsCategory.detail" class="runtime-template-container">
            <v-runtime-template :template="itemsCategory.detail"></v-runtime-template>
          </div>
          <el-row justify="center" :gutter="20">
            <el-col  style="padding-left:10px;padding-right: 10px;" v-for="item in itemsCategory.items" :key="item.id" :span="getItemColSpan" class="item-col">
              <el-button :type="selectedItems[itemsCategory.id] === item.id ? 'primary' : 'default'"
                :disabled="item.able === 0" @click="selectItem(item, itemsCategory.id)" class="item-button">
                {{ item.name }}
              </el-button>
            </el-col>
          </el-row>
          <div v-if="selectedItems[itemsCategory.id]" class="item-details">
            <el-row>
              <el-col :span="8"><el-text>时长:</el-text></el-col>
              <el-col :span="16"><el-text style="float: right">{{
                formatDuration(getSelectedItemDetails(itemsCategory.id).duration) }}</el-text></el-col>
            </el-row>
            <el-divider />
            <el-row v-if="getSelectedItemDetails(itemsCategory.id).detail">
              <el-col :span="8"><el-text>备注:</el-text></el-col>
              <el-col :span="16"><el-text style="float: right">{{ getSelectedItemDetails(itemsCategory.id).detail
                  }}</el-text></el-col>
            </el-row>
            <el-divider v-if="getSelectedItemDetails(itemsCategory.id).detail" />

            <el-row v-if="selectedItems[itemsCategory.id] && account.coupons && account.coupons.length > 0">
              <el-col :span="8"><el-text>优惠券:</el-text></el-col>
              <el-col :span="16">
                <el-select v-model="selectedCoupons[itemsCategory.id]" placeholder="选择优惠券"
                  style="float: right; width: 100%">
                  <el-option label="不使用" :value="null"></el-option>
                  <el-option v-for="coupon in account.coupons" :key="coupon.id" :label="formatCouponInfo(coupon)"
                    :value="coupon.id"
                    :disabled="!isCouponApplicable(coupon, getSelectedItemDetails(itemsCategory.id))"></el-option>
                </el-select>
              </el-col>
            </el-row>
            <el-divider v-if="selectedItems[itemsCategory.id] && account.coupons && account.coupons.length > 0" />
            <el-row>
              <el-col :span="8"><el-text>价格:</el-text></el-col>
              <el-col :span="16">
                <template v-if="selectedCoupons[itemsCategory.id] !== null">
                  <el-text tag="b" size="large" style="color: red; float: right">
                    ￥{{ calculateDiscountedPrice(getSelectedItemDetails(itemsCategory.id).price,
                      selectedCoupons[itemsCategory.id]) }}
                  </el-text>
                  <el-text style="margin-right: 10px;float: right; text-decoration: line-through">￥{{
                    getSelectedItemDetails(itemsCategory.id).price }}</el-text>
                </template>
                <template v-else>
                  <el-text tag="b" size="large" style="color: red; float: right">￥{{
                    getSelectedItemDetails(itemsCategory.id).price }}</el-text>
                </template>
              </el-col>
            </el-row>
          </div>
          <el-button style="width:100%" type="primary" @click="buyItem(itemsCategory.id)">购买</el-button>
        </el-card>
      </el-col>
    </el-row>

    <el-dialog v-model="qrCodeDialogVisible" title="扫码支付" width="300px" @close="stopPolling">
      <div style="text-align: center;">
        <vue-qr id="payQR" :text="qrCodeValue" :size="248" colorDark="#5559FF" colorLight="#ffffff">
        </vue-qr>
        <p>请使用支付宝扫码支付</p>
      </div>
    </el-dialog>
  </div>
</template>

<script setup>
import { ref, onMounted, onBeforeUnmount, computed, reactive } from 'vue';
import { ElMessage, ElMessageBox } from 'element-plus';
import axios from 'axios';
import VRuntimeTemplate from "vue3-runtime-template";
import dayjs from 'dayjs';
import "@/assets/font/font.css";
import { account, infos, autoLogin, isLoggedIn } from './store';
import vueQr from 'vue-qr/src/packages/vue-qr.vue'

const pollingInterval = ref(null);
const itemsCategories = reactive([]);
const selectedItems = reactive({});
const selectedCoupons = reactive({});
const windowWidth = ref(window.innerWidth);
const qrCodeDialogVisible = ref(false);
const tradeNo = ref('');
const qrCodeValue = ref('');  // Changed to ref

const formatDuration = (duration) => {
  const days = Math.floor(duration / 24);
  const hours = duration % 24;
  let result = '';
  if (days > 0) {
    result += `${days}天`;
  }
  if (hours > 0) {
    result += `${hours}小时`;
  }
  return result || '0小时';
};

const fetchItems = () => {
  axios.get('/job/getItems')
    .then(response => {
      // Sort the itemCategories by priority
      const sortedCategories = response.data.sort((a, b) => a.priority - b.priority);

      // Sort the items within each category by priority
      sortedCategories.forEach(category => {
        category.items.sort((a, b) => a.priority - b.priority);
      });

      // Update the itemsCategories reactive variable
      Object.assign(itemsCategories, sortedCategories);
    })
    .catch(error => {
      console.log(error);
    });
};

const selectItem = (item, categoryId) => {
  selectedItems[categoryId] = item.id;
  selectedCoupons[categoryId] = null; // Reset the selected coupon when a new item is selected
};

const getSelectedItemDetails = (categoryId) => {
  const itemId = selectedItems[categoryId];
  if (!itemId) return {};
  const category = itemsCategories.find(category => category.id === categoryId);
  return category.items.find(item => item.id === itemId) || {};
};

const isCouponApplicable = (coupon, item) => {
  return item.duration >= coupon.threshold;
};

const buyItem = async (categoryId) => {
  if (!isLoggedIn.value) {
    ElMessage({
      message: '请先登录',
      grouping: true,
      type: 'error',
    })
    return;
  }
  const selectedItemId = selectedItems[categoryId];
  const selectedCouponId = selectedCoupons[categoryId];
  if (!selectedItemId) {
    ElMessage.warning('请选择一个项目');
    return;
  }
  const item = getSelectedItemDetails(categoryId);
  const coupon = account.coupons.find(c => c.id === selectedCouponId) || {};

  try {
    const response = await axios.post('/job/pay',
      new URLSearchParams({
        phone: account.phone,
        itemId: item.id,
        couponId: coupon.id || null,
      }), {
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
    });

    if (response.data) {
      // Remove the used coupon from the account.coupons array
      if (selectedCouponId) {
        const couponIndex = account.coupons.findIndex(c => c.id === selectedCouponId);
        if (couponIndex !== -1) {
          account.coupons.splice(couponIndex, 1);
        }
      }

      tradeNo.value = response.data.tradeNo;
      qrCodeDialogVisible.value = true;
      qrCodeValue.value = response.data.qrCodeUrl;  // Directly update the ref value
      startPolling();
    }
  } catch (error) {
    console.error("支付请求失败", error);
  }
};

const startPolling = () => {
  pollingInterval.value = setInterval(async () => {
    try {
      const response = await axios.post('/job/getStatus', { tradeNo: tradeNo.value });
      if (response.data.status == 200) {
        ElMessage({
          message: '支付成功! 请到个人中心激活',
          type: 'success',
          duration: 2000,
        });
        qrCodeDialogVisible.value = false;
        stopPolling();
        autoLogin();
      }
    } catch (error) {
      console.error('获取支付状态失败', error);
    }
  }, 1000);
};

const stopPolling = () => {
  clearInterval(pollingInterval.value);
};

const updateWindowWidth = () => {
  windowWidth.value = window.innerWidth;
};

const getCardColSpan = computed(() => {
  if (windowWidth.value < 900) return 24;
  if (windowWidth.value < 1300) return 12;
  if (windowWidth.value < 1700) return 8;
  return 6;
});

const getItemColSpan = computed(() => {
  return 6;
});

const calculateDiscountedPrice = (price, couponId) => {
  const coupon = account.coupons.find(c => c.id === couponId);
  if (!coupon) return price;
  return (price * (coupon.discount / 100)).toFixed(2);
};

const formatCouponInfo = (coupon) => {
  const discountText = `${coupon.discount / 10}折优惠券`;
  const thresholdText = coupon.threshold ? `${formatDuration(coupon.threshold)}及以上可用` : '无门槛';
  const endTimeText = `到期于${dayjs(coupon.endTime).format('YYYY年MM月DD HH:mm')}`;
  return `${discountText} / ${thresholdText} / ${endTimeText}`;
};

onMounted(() => {
  window.addEventListener('resize', updateWindowWidth);
  fetchItems();
});

onBeforeUnmount(() => {
  window.removeEventListener('resize', updateWindowWidth);
});
</script>

<style scoped>
.card-margin-bottom {
  margin-bottom: 16px;
}

.runtime-template-container {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.item-details {
  margin-top: 8px;
}

.item-details * {
  font-size: 18px;
}

.item-button {
  width: 100%;
  margin-bottom: 5px;
}

.item-col {
  display: flex;
  justify-content: center;
}

.el-divider {
  margin-top: 10px;
  margin-bottom: 10px;
}

</style>
