<template>
  <div v-if="Object.keys(account).length">

    <el-row style="margin-bottom: 20px" justify="center" align="middle">
      <el-col style="text-align: center" :span="windowWidth < 768 ? 24 : 24">
        <v-runtime-template :template="infos.me"></v-runtime-template>
      </el-col>
    </el-row>

    <el-descriptions :column="2" border>
      <el-descriptions-item :span="computedSpan" label="账号">
        {{ account.phone }}
      </el-descriptions-item>

      <el-descriptions-item :span="computedSpan" label="邮箱">
        <template v-if="account.mail">
          {{ account.mail }}
        </template>
        <template v-else>
          <el-popover placement="top" width="200">
            <template #reference>
              <el-button type="primary" @click="openBindDialog">
                绑定
              </el-button>
            </template>
            <p>绑定邮箱可得1天PLUS会员激活码</p>
          </el-popover>
        </template>
      </el-descriptions-item>

      <el-descriptions-item :span="computedSpan" label="普通会员" class="membership">
        {{ countTime(account.UserTokenNormal?.expireTime) }}
      </el-descriptions-item>

      <el-descriptions-item :span="computedSpan" label="PLUS会员" class="membership">
        {{ countTime(account.UserTokenPlus?.expireTime) }}
      </el-descriptions-item>

      <el-descriptions-item :span="computedSpan" label="专属邀请码" class="membership">
        <span>{{ account.inviteCode }}</span>
        <el-icon @click="copyInviteCode" :size="22" style="cursor: pointer;float:right">
          <CopyDocument />
        </el-icon>
      </el-descriptions-item>

      <el-descriptions-item label="累计邀请人数" class="membership">
        {{ account.inviteCount }}
      </el-descriptions-item>

    </el-descriptions>

    <el-config-provider :locale="locale">
      <el-table :data="filteredData" border @sort-change="handleSortChange">

        <el-table-column label="我的激活码">
          <template v-slot:header>
            <div style="display: flex; align-items: center; justify-content: space-between;">
              <span>我的PLUS会员激活码</span>
              <div>
                <el-checkbox v-model="showUsedTokens" style="margin-right: 10px;">显示已使用</el-checkbox>
                <el-button type="primary" @click="openImportDialog">导入</el-button>
              </div>
            </div>
          </template>

          <el-table-column prop="userToken" label="激活码" sortable>
            <template v-slot="scope">
              <el-popover v-if="windowWidth < 768" placement="top">
                <template #reference>
                  <span>{{ truncateUserToken(scope.row.userToken) }}</span>
                </template>
                <p>{{ scope.row.userToken }}</p>
              </el-popover>
              <span v-else>{{ scope.row.userToken }}</span>
            </template>
          </el-table-column>
          <el-table-column prop="duration" label="时长" sortable :formatter="formatDuration"></el-table-column>
          <el-table-column prop="createTime" label="获得时间" sortable :formatter="formatDate"></el-table-column>
          <el-table-column prop="access" label="获得方式" sortable></el-table-column>
          <el-table-column prop="isUsed" label="操作" sortable>
            <template v-slot="scope">
              <el-button v-if="scope.row.isUsed" type="info" plain disabled>
                已使用
              </el-button>
              <el-button v-else type="primary" @click="useUserToken(scope.row)">
                使用
              </el-button>
            </template>
          </el-table-column>

        </el-table-column>
      </el-table>

      <div class="pagination-container">
        <el-pagination background layout="total, sizes" :total="account.myUserTokens.length" :page-size="pageSize"
          :page-sizes="[20, 50, 100, 500]" :small="windowWidth <= 768" @size-change="handleSizeChange"
          @current-change="handleCurrentChange" />
        <el-pagination background layout="prev, pager, next" :total="account.myUserTokens.length" :page-size="pageSize"
          :page-sizes="[20, 50, 100, 500]" :small="windowWidth <= 768" @size-change="handleSizeChange"
          @current-change="handleCurrentChange" />
      </div>
    </el-config-provider>
  </div>
  <div v-else>
    <el-spinner type="rectangular" color="#409EFF"></el-spinner>
  </div>

  <el-dialog v-model="bindDialogVisible" :width="dialogWidth" :before-close="handleBindClose" :append-to-body="true">
    <el-form :model="form">
      <el-form-item>
        <el-input v-model="form.mail" placeholder="邮箱">
          <template #prepend>
            <el-icon>
              <User />
            </el-icon>
          </template>
          <template #append>
            <el-button @click="sendVerificationCode" :disabled="countdown > 0">
              {{ countdown > 0 ? `${countdown}秒后重试` : '发送验证码' }}
            </el-button>
          </template>
        </el-input>
      </el-form-item>
      <el-form-item>
        <el-input v-model="form.code" placeholder="验证码">
          <template #prepend>
            <el-icon>
              <Checked />
            </el-icon>
          </template>
        </el-input>
      </el-form-item>
    </el-form>
    <div slot="footer" class="dialog-footer">
      <el-button @click="bindDialogVisible = false">取消</el-button>
      <el-button type="primary" @click="bindEmail">确认</el-button>
    </div>
  </el-dialog>

  <el-dialog title="导入激活码" v-model="importDialogVisible" :width="dialogWidth">
    <el-form :model="importForm">
      <el-form-item>
        <el-input v-model="importForm.userToken" placeholder="激活码">
          <template #prepend>
            <el-icon>
              <Lock />
            </el-icon>
          </template>
        </el-input>
      </el-form-item>
    </el-form>
    <div slot="footer" class="dialog-footer">
      <el-button @click="importDialogVisible = false">取消</el-button>
      <el-button type="primary" @click="importUserToken">确认</el-button>
    </div>
  </el-dialog>
</template>

<script setup>
import { ref, reactive, computed, onMounted, onBeforeUnmount } from 'vue';
import { ElMessage } from 'element-plus';
import { User, Lock, Checked, CopyDocument } from '@element-plus/icons-vue';
import axios from 'axios';
import dayjs from 'dayjs';
import { countTime } from './util';
import { account, infos, autoLogin } from './store';
import zhCn from 'element-plus/dist/locale/zh-cn.mjs'
import en from 'element-plus/dist/locale/en.mjs'
import VRuntimeTemplate from "vue3-runtime-template";

const language = ref('zh-cn')
const locale = computed(() => (language.value === 'zh-cn' ? zhCn : en))

const bindDialogVisible = ref(false);
const importDialogVisible = ref(false);
const form = reactive({
  mail: '',
  code: ''
});

const dialogWidth = ref('500px');
const importForm = reactive({ userToken: '' });

const openBindDialog = () => {
  bindDialogVisible.value = true;
};

const handleBindClose = () => {
  bindDialogVisible.value = false;
};

const bindEmail = async () => {
  if (!form.mail || !form.code) {
    ElMessage.error('请填写完整信息');
    return;
  }

  const emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
  if (!emailPattern.test(form.mail)) {
    ElMessage.error('邮箱格式不正确');
    return;
  }

  try {
    const response = await axios.post('/job/validateMailCheckCode', {
      loginToken: localStorage.getItem('loginToken'),
      mail: form.mail,
      checkCode: form.code
    });

    if (response.data.code === '200') {
      bindDialogVisible.value = false;
      ElMessage.success('邮箱绑定成功');
      autoLogin();
    } else {
      ElMessage.error(response.data.message);
    }
  } catch (error) {
    ElMessage.error('请求失败，请重试');
  }
};

const sendVerificationCode = () => {
  const loginToken = localStorage.getItem('loginToken');
  if (form.mail && loginToken) {
    axios.post('/job/sendMailCheckCode', {
      loginToken: loginToken,
      toMail: form.mail
    })
      .then(response => {
        if (response.data.code == "200") {
          ElMessage.success('发送成功');
          startCountdown();
        } else {
          ElMessage.error(response.data.message);
        }
      })
      .catch(error => {
        ElMessage.error('发送失败');
      });
  } else {
    ElMessage.error('请填写邮箱');
  }
};

const openImportDialog = () => {
  importDialogVisible.value = true;
};

const importUserToken = () => {
  if (importForm.userToken) {
    axios.post('/job/importUserToken', {
      userToken: importForm.userToken,
      phone: account.phone
    })
      .then(response => {
        if (response.data.status === 200) {
          ElMessage.success('导入成功');
          autoLogin();
          importDialogVisible.value = false;
        } else {
          ElMessage({
            message: response.data.message,
            grouping: true,
            type: 'error',
          })

        }
      })
      .catch(error => {
        ElMessage.error('导入失败');
      });
  } else {
    ElMessage.error('请输入 UserToken');
  }
};

const windowWidth = ref(window.innerWidth);

const updateWindowWidth = () => {
  windowWidth.value = window.innerWidth;
  if (windowWidth.value <= 768) {
    dialogWidth.value = '100%';
  } else {
    dialogWidth.value = '500px';
  }
};

onMounted(() => {
  window.addEventListener('resize', updateWindowWidth);
  updateWindowWidth(); // 初始化时检查窗口宽度
  initializeCountdown(); // 初始化倒计时
});

onBeforeUnmount(() => {
  window.removeEventListener('resize', updateWindowWidth);
});

const computedSpan = computed(() => {
  return windowWidth.value <= 768 ? 2 : 1;
});

const useUserToken = (row) => {
  axios.post('/job/activateUserToken', {
    userToken: row.userToken,
    phone: account.phone
  })
    .then(response => {
      if (response.data.status == 200) {
        autoLogin();
        ElMessage.success("使用成功");
      } else {
        ElMessage.error(response.data.message);
      }
    })
    .catch(error => {
      ElMessage.error(response.data.message);
    });
};

const formatDate = (row, column, cellValue) => {
  return cellValue ? dayjs(cellValue).format('YYYY年MM月DD日 HH:mm') : '未使用';
};

const truncateUserToken = (userToken) => {
  return userToken.length > 4 ? `${userToken.slice(0, 4)}...` : userToken;
};

const formatDuration = (row, column, cellValue) => {
  const days = Math.floor(cellValue / 24);
  const hours = cellValue % 24;
  let result = '';
  if (days > 0) {
    result += `${days}天`;
  }
  if (hours > 0) {
    result += `${hours}小时`;
  }
  return result || '0小时';
};

const showUsedTokens = ref(false);

const multipleSelection = ref([]);

// 分页和排序相关数据和方法
const currentPage = ref(1);
const pageSize = ref(20);
const sortField = ref('');
const sortOrder = ref('');

const filteredData = computed(() => {
  let data = account.myUserTokens.filter(token => showUsedTokens.value || !token.isUsed);

  if (sortField.value) {
    data = data.sort((a, b) => {
      let result = 0;
      if (a[sortField.value] < b[sortField.value]) {
        result = -1;
      } else if (a[sortField.value] > b[sortField.value]) {
        result = 1;
      }
      return sortOrder.value === 'ascending' ? result : -result;
    });
  }

  const start = (currentPage.value - 1) * pageSize.value;
  const end = start + pageSize.value;
  return data.slice(start, end);
});

const handleSortChange = ({ prop, order }) => {
  sortField.value = prop;
  sortOrder.value = order;
};

const handleSizeChange = (newSize) => {
  pageSize.value = newSize;
};

const handleCurrentChange = (newPage) => {
  currentPage.value = newPage;
};

const handleSelectionChange = (selection) => {
  multipleSelection.value = selection;
};

// Countdown related data and methods
const countdown = ref(0);
const countdownInterval = ref(null);

const startCountdown = () => {
  countdown.value = 60;
  localStorage.setItem('countdownEndTime', Date.now() + countdown.value * 1000);
  countdownInterval.value = setInterval(updateCountdown, 1000);
};

const updateCountdown = () => {
  const endTime = localStorage.getItem('countdownEndTime');
  const remainingTime = Math.floor((endTime - Date.now()) / 1000);

  if (remainingTime > 0) {
    countdown.value = remainingTime;
  } else {
    clearInterval(countdownInterval.value);
    countdown.value = 0;
  }
};

const initializeCountdown = () => {
  const endTime = localStorage.getItem('countdownEndTime');
  if (endTime) {
    const remainingTime = Math.floor((endTime - Date.now()) / 1000);
    if (remainingTime > 0) {
      countdown.value = remainingTime;
      countdownInterval.value = setInterval(updateCountdown, 1000);
    } else {
      localStorage.removeItem('countdownEndTime');
    }
  }
};

const copyInviteCode = async () => {
  try {
    await navigator.clipboard.writeText(account.inviteCode);
    ElMessage.success('邀请码已复制');
  } catch (error) {
    ElMessage.error('复制失败，请重试');
  }
};
</script>

<style>
.dialog-footer {
  text-align: right;
}

.membership {
  width: 100%;
}

.table-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
}

.pagination-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px 0;
}

@media (max-width: 768px) {
  .el-pagination.is-background .el-pager li {
    margin: 0 !important;
  }
}


.el-table {
  --el-table-border-color: var(--el-border-color-darker) !important
}
</style>
